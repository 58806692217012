<template>
    <div :data-tooltip="tooltip" :data-invalid="error">
        <select ref="select" :required="required" v-model="value" @focusout="validate">
            <option value="" disabled selected>{{ placeholder }}</option>
            <slot/>
        </select>
        <Icon name="angle"/>
    </div>
</template>

<script>
import Icon from '@/components/Icon.vue';

export default {
    name: 'Select',
    props: {
        placeholder: {
            type: String,
            default: '',
        },
        tooltip: {
            type: String,
            default: null,
        },
        required: {
            type: Boolean,
            default: false,
        },
        modelValue: String,
    },
    components: {
        Icon,
    },
    data() {
        return {
            error: null,
        };
    },
    computed: {
        value: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value);
            },
        },
    },
    methods: {
        validate(e) {
            if (this.required && e.code !== 'Tab') {
                this.error = this.value ? null : this.$refs.select.validationMessage;
            }
        },
    },
};
</script>

<style scoped lang="less">
div {
    position: relative;
}
</style>
