<template>
    <label>
        <input type="radio" v-model="checkbox" :value="value" :required="required" @click="$emit('click')">
        <span>{{ label }}</span>
    </label>
</template>

<script>
export default {
    name: 'Radio',
    props: {
        label: {
            type: String,
            default: '',
        },
        value: {
            type: String,
            required: true,
        },
        required: {
            type: Boolean,
            default: false,
        },
        modelValue: String,
    },
    emits: ['update:modelValue', 'click'],
    computed: {
        checkbox: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value);
            },
        },
    },
};
</script>
